import * as Sentry from '@sentry/vue';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
import pandoTooltip from './directives/pandoTooltip';
import sanitizeHtml from './directives/sanitizeHtml';
import scrollIntoView from './directives/scrollIntoView';
import routes from './routes';
import { SENTRY, VITE_GIT_BRANCH, VITE_GIT_COMMIT } from './scripts/Configuration.js';
import { getLogrocketSessionUrl } from './scripts/api/LogRocket.mjs';
import DoubleColumn from '@/layouts/DoubleColumn.vue';
import InboxLayout from '@/layouts/InboxLayout.vue';
import PageLayout from '@/layouts/PageLayout.vue';
import SingleColumn from '@/layouts/SingleColumn.vue';

const app = createApp(App);
const pinia = createPinia();

const router = createRouter({
  scrollBehavior(to) {
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ el: to.hash, behavior: 'smooth' });
        }, 500);
      });
    } return { top: 0 };
  },
  history: createWebHistory(),
  routes,
});

if (VITE_GIT_BRANCH !== 'local') {
  Sentry.init({
    beforeSend(event) {
      getLogrocketSessionUrl();
      return event;
    },
    app,
    environment: VITE_GIT_BRANCH,
    release: VITE_GIT_COMMIT,
    dsn: `https://${SENTRY.DSN}.ingest.sentry.io/${SENTRY.PROJECT_ID}`,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
    ],
    tracePropagationTargets: SENTRY.TRACING_ORIGINS,
    denyUrls: ['localhost'],
    attachStacktrace: true,
    tracesSampleRate: 0.5,
    sendDefaultPii: true,
  });
}

app.component('PageLayout', PageLayout);
app.component('SingleColumn', SingleColumn);
app.component('DoubleColumn', DoubleColumn);
app.component('InboxLayout', InboxLayout);

app.use(router);
app.use(pinia);

app.directive('pando-tooltip', pandoTooltip);
app.directive('scroll-into-view', scrollIntoView);
app.directive('sanitize-html', sanitizeHtml);

app.mount('#app');
